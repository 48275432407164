import { render, staticRenderFns } from "./MenuLeague.vue?vue&type=template&id=00e5b5da&scoped=true&"
import script from "./MenuLeague.vue?vue&type=script&lang=js&"
export * from "./MenuLeague.vue?vue&type=script&lang=js&"
import style0 from "./MenuLeague.vue?vue&type=style&index=0&id=00e5b5da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00e5b5da",
  null
  
)

export default component.exports