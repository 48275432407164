import Vue from "vue";
import App from "./App.vue";
import Routes from "./router";
import VueRouter from "vue-router";
import { DateTime } from "luxon";

import "./main.scss";
import store from "./store";
import i18n from "./i18n";
import Maska from "maska";
import vueCountryRegionSelect from "vue-country-region-select";

import SvgIcon from "@/components/SvgIcon.vue";
import SvgSprite from "@/components/SvgSprite.vue";

import { StripePlugin } from "@vue-stripe/vue-stripe";
import GoogleAuthSignIn from "@/components/GoogleAuthSignIn.vue";

const stripeOptions = {
  pk: process.env.VUE_APP_STRIPE_PK,
  apiVersion: "2020-08-27",
  locale: "auto",
  testMode: process.env.NODE_ENV === "development",
  stripeAccount: process.env.VUE_APP_STRIPE_CONNECT_ID,
};

Vue.use(StripePlugin, stripeOptions);

Vue.component("svg-icon", SvgIcon);
Vue.component("svg-sprite", SvgSprite);
Vue.component("google-auth-sign-in", GoogleAuthSignIn);

Vue.prototype.$siteUrl =
  window?.configs?.["VUE_APP_URL"] || process.env.VUE_APP_URL;
Vue.prototype.$bucketUrl =
  window?.configs?.["VUE_APP_BUCKET"] || process.env.VUE_APP_BUCKET;
Vue.prototype.$imgUrl = Vue.prototype.$siteUrl + "/images";
Vue.prototype.$apiUrl = Vue.prototype.$siteUrl + "/api";
Vue.prototype.$DateTime = DateTime;

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueRouter);
Vue.use(Maska);
Vue.use(vueCountryRegionSelect);

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

const router = new VueRouter({
  routes: Routes,
  mode: "history",
});

router.beforeResolve((to, from, next) => {
  store.dispatch("pageLoad", to.meta?.requiresLoading || false);
  next();
});

new Vue({
  el: "#app",
  components: { App },
  router,
  render: (h) => h(App),
  store,
  i18n,
  template: "<App/>",
});
