<template>
  <div class="google-button" @click="login">
    <div class="google-icon-wrapper">
      <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
    </div>
    <p class="btn-text"><b>{{ $t('registration.google') }}</b></p>
  </div>
</template>

<script>
import axios from 'axios'
export default{
  name: 'GoogleAuthSignIn',
  data() {
    return {
      tokenClient: null,
      loginClient: null,
    }
  },
  mounted() {
    window.google.accounts.id.initialize({
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      callback: this.handleCredentialResponse,
      auto_select: true
    })
  },
  methods: {
    login(){
      window.google.accounts.id.prompt(this.handleEvent);
    },
    handleEvent(event){
      if(event.isNotDisplayed()){
        this.delete_cookie('g_state')
        this.login()
      } 
    },
    delete_cookie( name, path, domain ) {
      if(this.get_cookie( name ) ) {
        document.cookie = name + "=" +
          ((path) ? ";path="+path:"")+
          ((domain)?";domain="+domain:"") +
          ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
      }
    },
    get_cookie(name){
      return document.cookie.split(';').some(c => {
          return c.trim().startsWith(name + '=');
      });
    },
    handleCredentialResponse(response) {
      if(response.error) return;
      axios.post(this.$apiUrl + '/auth/google/authenticate', {
        token: response.credential
      }).then(res => {
        if(res.data.status === 'Not Registered!'){
          this.showConsentSummary(res.data.consent_data, res.data.idToken)
        }else{
          this.$store.dispatch('login', res.data.token)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    showConsentSummary(consent_data, idToken){
      this.tokenClient = window.google.accounts.oauth2.initTokenClient({
        ...consent_data,
        select_account: true,
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        ux_mode: 'popup',   
        callback: (response) => this.handleTokenResponse(response, idToken),
      })
      this.tokenClient.requestAccessToken({prompt: '' })
    },
    handleTokenResponse(response, idToken) {
      if(response.error) return;
      axios.post(this.$apiUrl + '/auth/google/authorize', {
        accessToken: response.access_token,
        idToken: idToken
      }).then(res => {
        this.$store.dispatch('login', res.data.token)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss">
@import "@/theme.scss";
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

  .google-button {
    display: inline-flex;
    align-items: center;
    width: 30vw;
    height: 42px;
    background-color: $white;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    transition: box-shadow 0.3s, background-color 0.3s;
    cursor: pointer;

    .google-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 2px;
    }
    
    .google-icon {
      width: 18px;
      height: 18px;
    }
    
    .btn-text {
      color: black;
      font-size: 14px;
      letter-spacing: 0.2px;
      margin-left: 11px;
    }
    
    &:hover {
      box-shadow: 0 0 6px $google-blue; 
      background-color: #f5f5f5; 
    }
    
    &:active {
      background-color: #e0e0e0; 
      box-shadow: none; 
    }
  }

@media only screen and (max-width: 600px) {
  .google-button {
    width: 60vw;
  }
}
</style>