<template>
  <div id="app">
    <svg-sprite />
    <Loading v-show="$store.state.loading === true"/>
    <MenuBar v-show="$store.state.loading === false"/>
    <router-view :key="$route.fullPath" v-show="$store.state.loading === false"/>
    <Footer v-show="$store.state.loading === false"/>
  </div>
</template>

<script>
import MenuBar from "./components/menu/MenuBar.vue";
import Footer from "./components/Footer.vue";
import Loading from "./components/Loading.vue";
export default {
  name: "App",
  components: {
    MenuBar,
    Footer,
    Loading,
  },
  beforeCreate(){
    this.$store.dispatch('init');
    this.$i18n.locale = localStorage.getItem('lang') || "en";
  },
};
</script>
<style lang="scss">
@import "@/main.scss";
</style>

