<template>
  <div class="loading">
    <svg-icon icon="goal" :spin="true" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw !important;
  height: 100vh;
  z-index: 10000;
}
</style>