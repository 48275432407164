<template>
  <footer>
    <div class="sponsors">
      <h3>{{ $t("footer.ourPartners") }}</h3>
      <div class="sponsors-list">
        <a v-for="sponsor in sponsors" :key="sponsor._id" :href="sponsor.Link">
          <img
            class="sponsor-image"
            :class="{ darken: sponsor.Darken }"
            :src="`${$bucketUrl}/images/sponsors/${sponsor._id}.png`"
          />
        </a>
      </div>
    </div>
    <div class="info">
      <img class="info-logo" src="@/assets/TKL-white.png" />
      <div class="site-map">
        <h3>{{ $t("footer.links") }}</h3>
        <ul>
          <li>
            <router-link to="/">{{ $t("pages.home") }}</router-link>
          </li>
          <li>
            <router-link to="/about">{{ $t("pages.aboutUs") }}</router-link>
          </li>
          <li>
            <router-link to="/contact">{{ $t("pages.contactUs") }}</router-link>
          </li>
          <li>
            <a :href="`${$bucketUrl}/rules.pdf`" download>{{
              $t("pages.rules")
            }}</a>
          </li>
          <router-link to="/privacy-policy">{{ $t("pages.privacy_policy") }}</router-link>
        </ul>
      </div>

      <div class="social">
        <h3>{{ $t("footer.followUs") }}</h3>
        <a href="https://www.facebook.com/TKLeague/">
          <img src="/facebook.png" />
        </a>
        <a href="https://www.instagram.com/tkleague/">
          <img src="/instagram.png" />
        </a>
        <a href="https://www.youtube.com/@TheKingsLeague">
          <img src="/youtube-logo.png" />
        </a>
      </div>
    </div>
    <div class="copyright">
      <div>
        <p class="text-center">
          ©{{ new Date().getFullYear() }} The Kings League
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
export default {
  name: "Footer",
  data() {
    return {
      sponsors: [],
    };
  },
  async created() {
    let res = await axios.get(this.$apiUrl + "/sponsors");
    this.sponsors = res.data;
  },
};
</script>
<style lang="scss" scoped>
@import "@/theme.scss";

footer {
  background: rgb(34, 34, 34);
  color: #999;
  box-shadow: 0 50vh 0 50vh rgb(34, 34, 34);
  padding: 5px;
}
h3 {
  @extend %font-bold;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  margin: 15px 0;
}

.sponsors {
  h3 {
    text-align: center;
    color: #999;
  }
}
.sponsors-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  a {
    margin: 15px;
  }
}
.sponsor-image {
  display: block;
  height: 50px;
  cursor: pointer;
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0%);
  }
  &.darken {
    filter: grayscale(100%) brightness(0.5);
    &:hover {
      filter: grayscale(0%) brightness(1);
    }
  }
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 0 7.5px;
  max-width: $max-width;
  margin: 15px auto 30px auto;
  font-size: 20px;
}
.info-logo {
  width: 200px;
}
.site-map {
  width: 200px;
  font-size: 15px;
  text-align: center;
  ul {
    list-style-type: none;
  }
  a {
    color: #999;
    padding: 10px 0;
    &:hover {
      color: white;
    }
  }
}
.social {
  width: 200px;
  text-align: center;
  a {
    padding: 8px;
    img {
      width: 32px;
      filter: brightness(0.5);
      &:hover {
        filter: brightness(1);
      }
    }
  }
}
.copyright {
  p {
    text-align: center;
    padding: 5px;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .info {
    flex-direction: column;
    align-items: center;
    & > * {
      margin: 10px 0;
    }
  }
  .social {
    width: 100%;
    text-align: center;
  }
}
</style>
