<template>
  <li class="nav-lang">
    <span @click.prevent="toggle" class="name"><Flag :flag-code="langs[$i18n.locale].flag" class="flag" /></span>
    <ul class="sub-menu" v-show="expanded">
      <li v-for="(lang,key) in langs" :key="key" class="lang" @click="setLang(key)">
        <Flag :flag-code="lang.flag" class="flag" /> {{lang.name}}
      </li>
    </ul>
  </li>
</template>

<script>
import Flag from "@/components/Flag.vue";
export default {
  name: "NavArchive",
  components: {
    Flag,
  },
  props: ["seasons"],
  data() {
    return {
      expanded: false,
      langs: {
        "en": {name: "English", flag: "gbr"},
        "fr": {name: "Français", flag: "fra"},
        //"es": "Español",
      },
    };
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        window.addEventListener("click", this.close);
      } else {
        window.removeEventListener("click", this.close);
      }
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.expanded = false;
        window.removeEventListener("click", this.close);
      }
    },
    setLang(lang){
      this.$i18n.locale = lang;
      localStorage.setItem('lang',lang);
      this.expanded = false;
    },
  },
  watch: {
    $route() {
      this.expanded = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/main.scss";
.nav-lang {
  user-select: none;
  position: relative;
  .name {
    cursor: pointer;
  }
  .sub-menu {
    //display: flex;
    //flex-direction: column;
    background: #ddd;
    color: black;
    position: absolute;
    right: 0;
    top: $navbar-height;
    line-height: normal;
    border-radius: 5px;
    padding: 15px;
  }
}
.flag{
    display: inline-block;
    height: 1em;
    transform: translateY(3px);
  }
.lang {
  list-style-type: none;
  white-space: nowrap;
  a {
    text-decoration: none;
    color: black;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .nav-lang {
    .sub-menu {
      position: static;
      transform: none;
      margin-bottom: 15px;
    }
  }
}

@media only screen and (min-width: $mobile-breakpoint) {
  .nav-lang {
    .sub-menu::after {
      content: "";
      position: absolute;
      top: 0;
      right: 17px;
      width: 0;
      height: 0;
      border: 15px solid transparent;
      border-bottom-color: #ddd;
      border-top: 0;
      margin-left: -15px;
      margin-top: -15px;
    }
  }
}
</style>