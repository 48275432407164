<template>
  <header class="navbar" :class="{ transparent: transparent }">
    <div class="logo">
      <router-link
        v-if="$route.params.archiveID == null"
        to="/"
        class="logo-link"
      >
        <img src="@/assets/TKL_menu2.png" alt="TKL logo" />
      </router-link>
      <router-link v-else :to="`/archive/${$route.params.archiveID}`">{{
        $t("pages.archive")
      }}</router-link>
    </div>
    <ul class="menu" :class="{ expanded: expanded }">
      <li v-if="$route.params.archiveID != null" @click="backToHome">
        <router-link to="/">{{ $t("pages.home") }}</router-link>
      </li>
      <MenuLeague v-if="$store.state.menu[0]" :league="$store.state.menu[0]" />
      <li v-if="$route.params.archiveID == null">
        <router-link to="/about">{{ $t("pages.aboutUs") }}</router-link>
      </li>
      <li v-if="$route.params.archiveID == null">
        <router-link to="/contact">{{ $t("pages.contactUs") }}</router-link>
      </li>
      <li v-if="!loggedInPlayer && registrationEnabled">
        <router-link to="/register">{{ $t("pages.register") }}</router-link>
      </li>
      <li v-if="!loggedInPlayer && registrationEnabled">
        <router-link to="/login">{{ $t("pages.login") }}</router-link>
      </li>
      <li v-if="loggedInPlayer && registrationEnabled">
        <router-link to="/playerDashboard">{{
          $t("pages.dashboard")
        }}</router-link>
      </li>
      <li v-if="loggedInCaptain && registrationEnabled">
        <router-link to="/teamDashboard">{{
          $t("pages.dashboard")
        }}</router-link>
      </li>
      <li
        v-if="isLoggedIn"
        @click="logout()"
      >
        <router-link to="">{{ $t("pages.logout") }}</router-link>
      </li>
      <NavLang :seasons="seasons" @clicked="changeSeason" />
    </ul>
    <button class="expand-button" @click="expanded = !expanded"></button>
  </header>
</template>

<script>
import axios from "axios";
import MenuLeague from "./MenuLeague.vue";
import NavLang from "./NavLang.vue";
import { mapState } from "vuex";
export default {
  name: "NavBar",
  components: {
    MenuLeague,
    NavLang,
  },
  computed: {
    ...mapState(["user", "isLoggedIn"]),
  },
  data() {
    return {
      menu: [],
      seasons: [],
      transparent: false,
      expanded: false,
      loggedInPlayer: false,
      loggedInCaptain: false,
      registrationEnabled: false,
    };
  },
  methods: {
    changeSeason(id) {
      axios.get(this.$apiUrl + `/menu?seasonID=${id}`).then((res) => {
        this.menu = res.data;
      });
    },
    backToHome() {
      axios.get(this.$apiUrl + "/menu").then((res) => {
        this.menu = res.data;
      });
    },
    onScroll() {
      this.transparent =
        (this.$router.currentRoute.name === "home" ||
          this.$router.currentRoute.name === "archive-home") &&
        window.scrollY < 5;
    },
    logout() {
      this.$store.dispatch("logout")
      window.dispatchEvent(new CustomEvent('user-logout'));
      if(!window.location.href.includes('pug')){
        this.$router.push('/');
      }
    },
  },
  created() {
    axios.get(this.$apiUrl + "/registration/state").then((res) => {
      this.registrationEnabled = res.data.RegistrationState;
    });
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  watch: {
    $route() {
      this.transparent = this.$router.currentRoute.path === "/";
      this.expanded = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/main.scss";
.navbar {
  // @extend %primary-pattern;
  background-color: #000000cb;
  position: fixed;
  top: 0;
  left: 0;
  min-height: $navbar-height;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}
a {
  text-decoration: none;
  color: white;
}
h1 {
  font-size: 24px;
  height: $navbar-height;
  line-height: $navbar-height;
  padding: 0 24px;
  color: white;
  white-space: nowrap;
}
.menu {
  display: flex;
  text-transform: uppercase;
  list-style-type: none;
  & > li {
    height: $navbar-height;
    line-height: $navbar-height;
    font-size: 18px;
    padding: 0 35px;
    color: white;
  }
}
.expand-button {
  display: none;
  position: absolute;
  right: 15px;
  top: 15px;
  height: 40px;
  width: 55px;
  background-color: transparent;
  border: 1px solid #ffffff88;
  border-radius: 5px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  background-size: 80% 80%;
  background-position: center center;
  background-repeat: no-repeat;
}
.logo {
  width: 300px;
  transform: skewX(-6deg);
  margin-left: -5px;
  background-color: #ffc72c;
  .logo-link {
    display: block;
    height: $navbar-height;
  }
  img {
    padding: 0px;
    height: 55px;
    display: block;
    margin: auto;
    padding-top: 3px;
  }
}

@media only screen and (min-width: $mobile-breakpoint) {
  .transparent {
    background-color: #000000cb;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .navbar {
    background-size: auto 100vh;
  }
  .header {
    flex-direction: column;
  }
  .menu {
    display: none;
    flex-direction: column;
    flex-basis: 100%;
    & > li {
      height: auto;
    }
  }
  .expanded {
    display: flex;
  }
  .expand-button {
    display: block;
  }
}
</style>
