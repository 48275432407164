import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menu: [],
    loading: null,
    token: localStorage.getItem("userToken") || "",
    isLoggedIn: false,
    user: null,
  },
  mutations: {
    setMenu(state, payload) {
      state.menu = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    removeToken(state) {
      state.token = null;
    },
    setIsLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    }
  },
  actions: {
    async init({ commit, state }) {
      let urlParams = new URLSearchParams(window.location.search);
      let seasonQuery = urlParams.has('seasonID') ? "?seasonID=" + urlParams.get('seasonID') :"";
      axios.get(Vue.prototype.$apiUrl + "/menu" + seasonQuery).then((res) => {
        commit("setMenu", res.data);
      });
      if (state.token) {
        axios.defaults.headers.common["token"] = state.token;
        await axios.get(Vue.prototype.$apiUrl + "/auth/verify-token")
        .then(() => {
          this.dispatch("login", state.token);
        })
        .catch(() => {
          this.dispatch("logout");
        });
      }else{
        this.dispatch("logout");
      }
    },
    login({ commit }, payload) {
      commit("setToken", payload);
      commit("setIsLoggedIn", true);
      commit("setUser", JSON.parse(atob(payload.split('.')[1])));
      localStorage.setItem("userToken", payload);
      axios.defaults.headers.common["token"] = payload;
    },
    logout({ commit }) {
      commit("removeToken");
      commit("setIsLoggedIn", false);
      commit("setUser", null);
      localStorage.removeItem("userToken");
      axios.defaults.headers.common["token"] = null;
    },
    pageLoad({ commit }, data) {
      commit("setLoading", data);
      window.scrollTo(0, 0);
    }
  },
  getters: {
    menu: (state) => state.menu,
    loading: (state) => state.loading,
    token: (state) => state.token,
    isLoggedIn: (state) => state.isLoggedIn,
    user: (state) => state.user,
  },
});
