<template>
  <li class="nav-sub-bar">
    <span @click.prevent="toggle" class="name">{{ $t("pages.leagues") }}</span>
    <div class="sub-menu" v-show="expanded">
      <div
        v-for="(section, sindex) in sections"
        v-show="section.length"
        :key="sindex"
        class="section"
      >
        <h3>{{ $t("common.days." + days[sindex]) }}</h3>
        <div class="sub-sections">
          <div
            class="sub-section"
            v-for="(subsection, ssindex) in section"
            :key="ssindex"
          >
            <h4>{{ subsection.Name }}</h4>
            <ul>
              <li
                v-for="(division, dindex) in subsection['Divisions']"
                :key="dindex"
                class="division"
              >
                <router-link
                  v-if="$route.params.archiveID == null"
                  :to="`/league/${division._id}${$route.query.seasonID ? '/' + $route.query.seasonID : ''}`"
                  >{{ division.Name }}</router-link
                >
                <router-link
                  v-else
                  :to="`/archive/${$route.params.archiveID}/league/${division._id}`"
                  >{{ division.Name }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "NavSubMenu",
  props: ["league"],
  data() {
    return {
      expanded: false,
      days: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
    };
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        window.addEventListener("click", this.close);
      } else {
        window.removeEventListener("click", this.close);
      }
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.expanded = false;
        window.removeEventListener("click", this.close);
      }
    },
  },
  watch: {
    $route() {
      this.expanded = false;
    },
  },
  computed: {
    sections() {
      let arr = [[], [], [], [], [], [], []];
      for (const childLeague of this.league["childLeagues"]) {
        arr[childLeague.DayOfWeek - 1].push(childLeague);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/main.scss";
.nav-sub-bar {
  user-select: none;
  position: relative;
  .name {
    cursor: pointer;
  }
}
.sub-menu {
  display: flex;
  background: rgb(236, 236, 236);
  color: black;
  position: absolute;
  transform: translateX(-50%);
  top: $navbar-height;
  line-height: normal;
  border-radius: 1px;
  white-space: nowrap;
}
.section {
  margin: 10px;
  h3 {
    border-bottom: #858585 1px solid;
    @extend %font-bold;
  }
}
.sub-sections {
  display: flex;
}
.sub-section {
  padding: 3px;
  h4 {
    @extend %font-normal;
  }
  ul {
    @extend %font-light;
  }
}
.division {
  list-style-type: none;
  a {
    text-decoration: none;
    padding: 1px;
    color: black;
  }
  .router-link-active {
    color: $secondary;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .sub-menu {
    position: static;
    transform: none;
    flex-direction: column;
  }
  .sub-sections {
    flex-direction: column;
  }
}

@media only screen and (min-width: $mobile-breakpoint) {
  .nav-sub-bar {
    .sub-menu::after {
      content: "";
      position: absolute;
      top: 0;
      left: 55%;
      width: 0;
      height: 0;
      border: 15px solid transparent;
      border-bottom-color: #ddd;
      border-top: 0;
      margin-left: -15px;
      margin-top: -15px;
    }
  }
}
</style>
