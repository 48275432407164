<template>
    <img :src="flag(flagCode)"/>
</template>

<script>
export default {
  props: ["flagCode"],
  methods: {
    flag(code) {
      var images = require.context("../assets/flags/", false, /\.svg$/);
      return images("./" + code + ".svg");
    }
  }
};
</script>

<style>
</style>