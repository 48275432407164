export default [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: { requiresLoading: true },
  },
  {
    path: "/about",
    component: () => import("@/views/About.vue"),
  },
  {
    path: "/contact",
    component: () => import("@/views/Contact.vue"),
  },
  {
    path: "/privacy-policy",
    component: () => import("@/views/PrivacyPolicy.vue"),
  },
  {
    path: "/league/:id/:seasonID?",
    name: "league",
    component: () => import("@/views/League.vue"),
    meta: { requiresLoading: true },
  },
  {
    path: "/playerDashboard",
    name: "playerDashboard",
    component: () => import("@/views/playerDashboard.vue"),
  },
  {
    path: "/teamDashboard",
    component: () => import("@/views/teamDashboard.vue"),
  },
  {
    path: "/register",
    component: () => import("@/views/Register.vue"),
  },
  {
    path: "/login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/players/:id",
    component: () => import("@/views/Player.vue"),
    meta: { requiresLoading: true },
  },
  {
    path: "/team/:id/:leagueID/:seasonID?",
    component: () => import("@/views/Team.vue"),
    meta: { requiresLoading: true },
  },
  {
    path: "/team/:id",
    component: () => import("@/views/TeamOverview.vue"),
    meta: { requiresLoading: true },
  },
  {
    path: "/event/:id",
    component: () => import("@/views/Event.vue"),
    meta: { requiresLoading: true },
  },
  {
    path: "/archive/:archiveID",
    name: "archive-home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/archive/:archiveID/team/:id",
    component: () => import("@/views/Team.vue"),
  },
  {
    path: "/archive/:archiveID/league/:id",
    name: "archive-league",
    component: () => import("@/views/League.vue"),
  },
  {
    path: "/statistics",
    component: () => import("@/views/Statistics.vue"),
  },
  {
    path: "/pug",
    component: () => import("@/views/PUG/Main.vue"),
  },
  {
    path: "/pug/faq",
    component: () => import("@/views/PUG/FAQ.vue"),
  },
  {
    path: "/paymentSuccess",
    name: "paymentSuccess",
    component: () => import("@/views/PaymentSuccess.vue"),
  },
  {
    path: "/verified",
    name: "verification",
    component: () => import("@/views/AccountVerified.vue")
  },
  {
    path: "/*",
    component: () => import("@/views/404.vue"),
  },
];
